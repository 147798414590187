
import {defineComponent, onMounted, ref, watch} from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import ActivityService from "@/core/services/ActivityService";

export default defineComponent({
  name: "InquiryStatus",
  components: {Avatar, DateTimeFormat},
  props: {
    inquiryStatusId: {type: String},
    isClaim: {required: false}
  },
  setup(props) {
    const inquiryStatus = ref({});
    onMounted(async () => {
      if (props.inquiryStatusId) {
        inquiryStatus.value = await ActivityService.get(props.inquiryStatusId)
      } else {
        inquiryStatus.value = {};
      }
    })
    watch(() => props.inquiryStatusId, async (cb) => {
      if (cb) inquiryStatus.value = await ActivityService.get(cb)
      else inquiryStatus.value = {};
    })
    return {
      inquiryStatus,
    }
  }
})
